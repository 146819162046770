import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import TravelGuide from "./Pages/TravelGuide";
import './App.css'
import CookieConsent from "react-cookie-consent";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// function loadGTM() {
//   const script = document.createElement("script");
//   script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WT55PC3W";
//   script.async = true;
//   document.head.appendChild(script);
// }

function App() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("en");
  // useEffect(() => {
  //   loadGTM();
  // }, []);

  return (
       <div className="min-h-screen  bg-cover bg-center bg-fixed">
    
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="mySiteCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "consent_given",
          });
        }}
      >
        This site uses cookies. Please accept cookies for a better user
        experience.
      </CookieConsent>
      <Router>
      <Navbar language={language} setLanguage={setLanguage} t={t}/>
        <Routes>
          <Route path="/" element={<Home t={t}/>} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/travelGuide" element={<TravelGuide />} />
        </Routes>
        <Footer t={t}/>
      </Router>
    </div>
  );
}

export default App;
