import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-GB");
    setDate(formattedDate);
  }, []);

  const sections = [
    "informationCollected",
    "informationUse",
    "cookies",
    "dataSharing",
    "security",
    "externalLinks",
    "ageRestriction",
    "userRights",
    "policyUpdates",
    "contact",
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <article className="bg-black/20 text-white mt-20 md:p-8">
        <h2 className="text-2xl md:text-3xl font-bold mb-4">
          {t("privacyPolicy.title")}
        </h2>
        <p className="mb-6">
          {t("privacyPolicy.date.label")}
          {date}
        </p>

        <div className="prose prose-invert max-w-none">
          {sections?.map((section) => (
            <div key={section} className="mb-8">
              <h3 className="text-xl md:text-2xl font-semibold mb-3">
                {t(`privacyPolicy.sections.${section}.title`)}
              </h3>
              <div className="space-y-4">
                <p>{t(`privacyPolicy.sections.${section}.content`)}</p>

                {Array.isArray(
                  t(`privacyPolicy.sections.${section}.bulletPoints`, {
                    returnObjects: true,
                  })
                ) &&
                  t(`privacyPolicy.sections.${section}.bulletPoints`, {
                    returnObjects: true,
                  }).length > 0 && (
                    <ul className="list-disc pl-6 space-y-2">
                      {t(`privacyPolicy.sections.${section}.bulletPoints`, {
                        returnObjects: true,
                      }).map((point, index) => (
                        <li key={index}>{point}</li>
                      ))}
                    </ul>
                  )}

                {section === "informationCollected" && (
                  <>
                    <p className="font-semibold mt-4">
                      {t(`privacyPolicy.sections.${section}.pii`)}
                    </p>
                    <p className="font-semibold">
                      {t(`privacyPolicy.sections.${section}.nonPii`)}
                    </p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <footer className="mt-8 pt-4 border-t border-gray-600">
          <p className="text-sm">
            {t("privacyPolicy.date.lastUpdated")}
            {date}
            <br />
            {t("privacyPolicy.footer.tagline")}
          </p>
        </footer>
      </article>
    </div>
  );
};

export default Privacy;
