import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Twitter, Instagram, Facebook, Linkedin } from "lucide-react";
import logo from "../images/Blue and Purple Neon Gamer Logo.webp";
import enFlag from "../images/en.png";
import geFlag from "../images/german.svg";
import i18n from "../language/i18n";

const Navbar = ({ language, setLanguage, t }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    setIsOpen(false);
    i18n
      .changeLanguage(lng)
      .then(() => {
        setLanguage(lng);
        console.log("Language changed to:", lng);
        toggleDropdown();
      })
      .catch((error) => {
        console.error("Error changing language:", error);
      });
  };

  const navLinks = [
    { path: "/", text: t('navbar.home') },
    { path: "/about", text: t('navbar.about') },
    { path: "/travelGuide", text: t('navbar.travelGuide') },
    { path: "/contact", text: t('navbar.contact') },
  ];

  return (
    <>
      {/* Main Navbar */}
      <nav className="py-4 px-4 bg-black/40 text-white transition-all duration-300 w-full fixed z-10 ">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Link to="/">
                <img
                  src={logo}
                  alt="nav logo"
                  className="w-[100px] h-[70px] object-contain"
                />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <ul className="hidden md:flex  justify-items-center gap-8">
              {navLinks.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className="capitalize font-bold text-lg text-gray-100 hover:text-gray-400 transition-colors duration-300"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
              <div className="grid grid-cols-4 gap-4 justify-items-center	">
                <a
                  href="#"
                  className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
                >
                  <Twitter size={24} />
                </a>

                <a
                  href="#"
                  className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
                >
                  <Instagram size={24} />
                </a>

                <a
                  href="#"
                  className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
                >
                  <Facebook size={24} />
                </a>

                <a
                  href="#"
                  className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
                >
                  <Linkedin size={24} />
                </a>
              </div>

<div className="relative">
  <div 
    onClick={toggleDropdown} 
    className="flex items-center gap-2 cursor-pointer hover:text-gray-400 transition-colors duration-300"
  >
    <span className="font-bold text-lg">
      {language === "en" ? "EN" : "GE"}
    </span>
    <img 
      src={language === "en" ? enFlag : geFlag} 
      alt="Selected language flag" 
      className="w-6 h-4 object-cover"
    />
  </div>
  
  {isOpen && (
    <ul className="absolute right-0 mt-2 py-2 w-32 bg-black/90 rounded-md shadow-lg">
      <li 
        onClick={() => changeLanguage("en")} 
        className="flex items-center gap-2 px-4 py-2 hover:bg-black/60 cursor-pointer"
      >
        <span className="font-bold">EN</span>
        <img 
          src={enFlag} 
          alt="English flag" 
          className="w-6 h-4 object-cover"
        />
      </li>
      <li 
        onClick={() => changeLanguage("ge")} 
        className="flex items-center gap-2 px-4 py-2 hover:bg-black/60 cursor-pointer"
      >
        <span className="font-bold">GE</span>
        <img 
          src={geFlag} 
          alt="German flag" 
          className="w-6 h-4 object-cover"
        />
      </li>
    </ul>
  )}
</div>

{/* Rest of your nav code remains the same */}
            </ul>

            {/* Mobile Menu Button */}
            <button
              onClick={toggleSidebar}
              className="md:hidden text-blue-500 p-2"
              aria-label="Toggle Menu"
            >
              <Menu size={32} />
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Sidebar */}
      <div
        className={`fixed inset-0 bg-black/90 z-50 transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col h-full relative p-8">
          <button
            onClick={toggleSidebar}
            className="absolute top-4 right-4 text-blue-500 hover:text-blue-400 transition-colors duration-300"
            aria-label="Close Menu"
          >
            <Menu size={32} />
          </button>

          <div className="flex flex-col items-center justify-center h-full">
            <ul className="flex flex-col items-center gap-6">
              {navLinks.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    onClick={toggleSidebar}
                    className="text-2xl capitalize text-white hover:text-blue-500 transition-colors duration-300"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>

            {/* Social Icons */}
            <div className="grid grid-cols-4 gap-4 mt-12 w-80  justify-items-center	">
              <a
                href="#"
                className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
              >
                <Twitter size={24} />
              </a>

              <a
                href="#"
                className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
              >
                <Instagram size={24} />
              </a>

              <a
                href="#"
                className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
              >
                <Facebook size={24} />
              </a>

              <a
                href="#"
                className="text-gray-300 hover:text-blue-500 transition-colors duration-300"
              >
                <Linkedin size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
