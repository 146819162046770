import React from 'react';
import { useTranslation } from 'react-i18next';
import { cards } from '../components/data';

const Card = ({ imgSrc, imgAlt, title, description }) => {
  const { t } = useTranslation();
  
  return (
    <div className="h-[27rem] relative rounded-lg group perspective-[1500px]">
      {/* Front Side */}
      <div className="transition-all duration-[2s] backface-hidden absolute inset-0 w-full h-full rounded-lg transform-gpu group-hover:rotate-y-180">
        <div className="h-full flex flex-col">
          <img 
            src={imgSrc} 
            alt={imgAlt} 
            loading="lazy"
            className="h-52 w-full object-cover rounded-t-lg" 
          />
          <div className="p-6 flex-1 flex flex-col">
            <h4 className="text-white font-semibold text-xl mb-3">{title}</h4>
            <p className="text-white text-base leading-relaxed">{description}</p>
          </div>
        </div>
      </div>

      {/* Back Side */}
      <div className="transition-all duration-[2s] backface-hidden absolute inset-0 w-full h-full rounded-lg transform-gpu rotate-y-180 bg-purple-900/80 group-hover:rotate-y-0">
        <div className="h-full flex items-center justify-center">
          <button className="bg-white text-purple-700 px-6 py-3 rounded-lg uppercase font-bold tracking-wider hover:bg-gray-100 transition-colors">
            {t('travelGuide.button')}
          </button>
        </div>
      </div>
    </div>
  );
};

const TravelGuide = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-neon-pattern bg-cover bg-center bg-fixed">
      <main>
        <section className="py-16">
          <div className="text-center mb-16 my-16">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              {t('travelGuide.title')}
            </h2>
            <div className="w-20 h-1 bg-custom-green mx-auto"></div>
          </div>
          <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-28 gap-x-4">
            {cards.map((card, index) => (
              <Card 
                key={index}
                imgSrc={card?.img}
                imgAlt={t('travelGuide.altText.cardImage')}
                title={card.title}
                description={card.text}
              />
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default TravelGuide;