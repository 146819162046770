import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-cover bg-center">
      <section className="py-8 text-center text-white">
        <div className="mt-20">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">
            {t('about.title')}
          </h1>
          <div className="w-20 h-1 bg-custom-green mx-auto mb-5"></div>
        </div>

        <div className="max-w-3xl mx-auto px-4 md:px-6">
          <div className="p-6 md:p-10 text-2xl border-custom-green">
            <p className="mb-5">
              {t('about.intro')}
            </p>

            <h3 className="text-xl md:text-2xl font-bold mb-4">
              {t('about.mission.title')}
            </h3>
            <p className="mb-5">
              {t('about.mission.description')}
            </p>

            <h3 className="text-xl md:text-2xl font-bold mb-4">
              {t('about.distinction.title')}
            </h3>
            <p className="mb-5">
              <strong>{t('about.distinction.reviews.title')}</strong>{' '}
              {t('about.distinction.reviews.description')}
            </p>

            <p className="mb-5">
              <strong>{t('about.distinction.advisory.title')}</strong>{' '}
              {t('about.distinction.advisory.description')}
            </p>

            <h3 className="text-xl md:text-2xl font-bold mb-4">
              {t('about.experience.title')}
            </h3>
            <p className="mb-5">
              {t('about.experience.description')}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;