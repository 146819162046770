// src/pages/Home.js

import CardAbout from "../components/CardAbout";
import Items from "../components/Items";

const Home = ({t}) => {
  return (
    <div className="body ">
      <section className="pb-16 pt-32">
        <div className="max-w-8xl mx-auto px-4 text-white text-center">
          <article className="lg:self-center max-w-4xl mx-auto lg:bg-opacity-90 lg:border-4 lg:border-green-500 lg:text-white lg:p-10 lg:rounded-lg">
            <h2 className="text-3xl font-bold text-center mb-8">
              {t("home.welcome.title")}
            </h2>
            <p className="text-lg leading-relaxed">
              {t("home.welcome.description")}
            </p>
          </article>
        </div>
      </section>
      <Items t={t}/>
      <CardAbout t={t}/>
    </div>
  );
};

export default Home;
