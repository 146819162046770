import React from "react";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  const sections = [
    "acceptance",
    "services",
    "ageRestriction",
    "intellectualProperty",
    "userConduct",
    "accuracy",
    "liability",
    "externalLinks",
    "privacy",
    "termination",
    "governingLaw",
    "changes",
    "contact",
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <article className="bg-black/20 text-white md:p-2 mt-20">
        <h1 className="text-3xl md:text-4xl font-bold mb-8">
          {t("terms.title")}
        </h1>

        {sections?.map((section) => (
          <div key={section} className="mb-8">
            <h2 className="text-2xl font-bold mb-4">
              {t(`terms.sections.${section}.title`)}
            </h2>
            <div className="space-y-4">
              <p className="leading-relaxed">
                {t(`terms.sections.${section}.content`)}
              </p>

              {/* Render bullet points if they exist */}
              {Array.isArray(
                t(`terms.sections.${section}.bulletPoints`, {
                  returnObjects: true,
                })
              ) && (
                <ul className="list-disc pl-6 space-y-2">
                  {t(`terms.sections.${section}.bulletPoints`, {
                    returnObjects: true,
                  }).map((point, index) => (
                    <li key={index} className="leading-relaxed">
                      {point}
                    </li>
                  ))}
                </ul>
              )}

              {/* Special handling for contact section */}
              {section === "contact" && (
                <a
                  href={`mailto:${t("terms.sections.contact.email")}`}
                  className="text-custom-green hover:text-custom-green-hover underline"
                >
                  {t("terms.sections.contact.email")}
                </a>
              )}
            </div>
          </div>
        ))}
      </article>
    </div>
  );
};

export default TermsAndConditions;
