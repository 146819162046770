 
import { data } from '../components/data';
 // Inside your component:
export default function Items({t}) {
  return (
    <div className="w-full md:w-11/12 lg:w-4/5 mx-auto px-4 md:px-6">
      {data?.map((item, idx) => (
        <article 
          key={idx}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 bg-black/80 p-4 md:p-6 mb-8 rounded-lg text-center text-white items-center border-4 border-green-500 shadow-[10px_-17px_21px_5px_rgb(1,46,7)]"
        >
          {/* Logo Section */}
          <div className="w-full h-48 sm:h-full">
            <img 
              src={item?.logo} 
              alt={item?.alt || t('pokerRooms.altText.roomLogo')} 
              loading="lazy"
              className="w-full h-full object-cover rounded-lg" 
            />
          </div>
  
          {/* Score and Stars Section */}
          <div className="flex flex-col items-center py-4 sm:py-0">
            <h4 className="flex gap-2.5 text-white text-4xl md:text-[45px]">
              {item?.score}
            </h4>
            <div className="flex gap-2.5 text-yellow-400 text-lg md:text-[22px] mt-2">
              {item?.stars}
            </div>
          </div>
  
          {/* Contact Information */}
          <ul className="text-left space-y-2 px-4 sm:px-0">
            <li className="break-words">
              <span className="font-semibold">{t('pokerRooms.contactInfo.email')}:</span> {item?.email}
            </li>
            <li>
              <span className="font-semibold">{t('pokerRooms.contactInfo.phone')}:</span> {item?.phone}
            </li>
            <li className="break-words">
              <span className="font-semibold">{t('pokerRooms.contactInfo.address')}:</span> {item?.adress}
            </li>
          </ul>
  
          {/* Visit Button */}
          <div className="h-20 sm:h-[130px] w-full">
            <a 
              href={item?.link} 
              className="flex justify-center items-center w-full h-full bg-green-600 hover:bg-green-700 text-white font-extrabold rounded-lg transition-colors duration-300"
              target="_blank" 
              rel="noopener noreferrer"
            >
              {t('pokerRooms.actions.visit')}
            </a>
          </div>
        </article>
      ))}
    </div>
  );
}
