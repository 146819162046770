// src/components/Footer.js

import { Link } from "react-router-dom";
import logo from "../images/Blue and Purple Neon Gamer Logo.webp";

  
  const Footer = ({t}) => {
  
    return (
      <footer className="bg-black/70 text-white">
        <ul className="flex flex-col md:flex-row justify-around gap-4 md:gap-8 py-4 items-center">
          <li>
            <Link to="/" className="hover:text-green-500 transition-colors">
              <img
                src={logo}
                alt={t('footer.altText.logo')}
                className="w-[100px] h-[70px] object-contain"
              />
            </Link>
          </li>
          <li>
            <Link to="/" className="hover:text-green-500 transition-colors">
              {t('footer.navigation.home')}
            </Link>
          </li>
          <li>
            <Link to="/about" className="hover:text-green-500 transition-colors">
              {t('footer.navigation.about')}
            </Link>
          </li>
          <li>
            <Link to="/travelGuide" className="hover:text-green-500 transition-colors">
              {t('footer.navigation.travelGuide')}
            </Link>
          </li>
          <li>
            <Link to="/contact" className="hover:text-green-500 transition-colors">
              {t('footer.navigation.contact')}
            </Link>
          </li>
          <li>
            <Link to="/privacy" className="hover:text-green-500 transition-colors">
              {t('footer.navigation.privacy')}
            </Link>
          </li>
          <li>
            <Link to="/terms" className="hover:text-green-500 transition-colors">
              {t('footer.navigation.terms')}
            </Link>
          </li>
        </ul>
  
        <div className="px-5 py-2.5 border-t-2 border-gray-600 text-sm md:text-base leading-relaxed">
          {t('footer.disclaimer')}
        </div>
      </footer>
    );
  };
  
  export default Footer;

