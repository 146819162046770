import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
 
const {t}= useTranslation()

    const [showModal, setShowModal] = useState(false);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setShowModal(true);
    };
  

    
    return (
      <section className="py-16 min-h-screen">
        <div className="text-center mb-16 my-36">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
            {t('contact.title')}
          </h1>
          <div className="w-20 h-1 bg-custom-green mx-auto"></div>
        </div>
    
        <h3 className="text-white text-xl text-center w-3/4 mx-auto mb-8">
          {t('contact.subtitle')}
        </h3>
    
        <form 
          onSubmit={handleSubmit}
          className="w-full md:w-[650px] mx-auto bg-white/80 p-8 font-serif"
        >
          <div className="space-y-6">
            <label className="block">
              <span className="inline-block w-40 text-purple-900">
                {t('contact.form.name.label')}
              </span>
              <input 
                type="text" 
                required
                placeholder={t('contact.form.name.placeholder')}
                className="w-full md:w-[275px] bg-transparent border-b border-dashed border-blue-300 outline-none p-0 italic text-purple-900 focus:border-purple-900 transition-colors"
              />
            </label>
    
            <label className="block">
              <span className="inline-block w-40 text-purple-900">
                {t('contact.form.email.label')}
              </span>
              <input 
                type="email" 
                required
                placeholder={t('contact.form.email.placeholder')}
                className="w-full md:w-[275px] bg-transparent border-b border-dashed border-blue-300 outline-none p-0 italic text-purple-900 focus:border-purple-900 transition-colors"
              />
            </label>
    
            <label className="block">
              <span className="inline-block w-40 text-purple-900">
                {t('contact.form.subject.label')}
              </span>
              <input 
                type="text" 
                required
                placeholder={t('contact.form.subject.placeholder')}
                className="w-full md:w-[275px] bg-transparent border-b border-dashed border-blue-300 outline-none p-0 italic text-purple-900 focus:border-purple-900 transition-colors"
              />
            </label>
    
            <div className="pt-4">
              <button 
                type="submit"
                className="bg-blue-500 hover:bg-blue-400 text-black px-4 py-2 rounded transition-colors"
              >
                {t('contact.form.submitButton')}
              </button>
            </div>
          </div>
        </form>
    
        {showModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 relative max-w-md w-full mx-4">
              <button 
                onClick={() => setShowModal(false)}
                className="absolute top-2 right-2 text-3xl text-gray-600 hover:text-gray-800"
                aria-label={t('contact.modal.closeButton')}
              >
                &times;
              </button>
              <div className="bg-green-500 text-white p-4 rounded text-center mt-4">
                {t('contact.modal.success')}
              </div>
            </div>
          </div>
        )}
      </section>
    );
  };
  


export default Contact;

